import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'TJ | Portfolio', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my portfolio page', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: '',
  name: 'TJ.',
  subtitle: 'Making a difference, one line of code at a time. ',
  cta: 'Learn more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile1.jpg',
  paragraphOne:
    'Tech-Savvy Sales Leader turned Software Developer, following a 17-year career spanning retail, management, and various service based roles.',
  paragraphTwo:
    'Being a keen sports fan(Go Hawks!) from a young age led me to the discovery of statistics & this started me down the road to wanting to develop & learn more about the insight/analytical aspect of different subjects. Now in the midst of a pivotal career change, I’m keen to build upon my knowledge & continue learning new technologies.',
  paragraphThree:
    "Out of the office you'll find me building tech based solutions, trying to source the best sushi & pizza around town, playing and/or watching sport, climbing the odd hill and where possible binge watching the latest movies or TV series.",
  // resume: 'https://gitconnected.com/tjp206/resume', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project.png',
    title: 'The Score Clan App',
    info:
      'A solo project that checks recent football scores, highlights & other various game/team/competition related information.',
    info2: 'Tech Stack: Vue.js, Node.js, HTML/CSS, Netlify',
    url: 'http://the-score-clan.netlify.app/',
    repo: 'https://github.com/tjp206/score_clan_app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project1.png',
    title: 'Connect Force App',
    info:
      'A group project built to play a browser based Connect Four game. Please note there is no live version of this app.',
    info2: 'Tech Stack: Vue.js, Node.js, MongoDB, HTML/CSS.',
    url: 'https://github.com/tjp206/c4_game',
    repo: 'https://github.com/tjp206/c4_game', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.png',
    title: 'NHS Health App',
    info:
      'Designed to search through the NHS API using voice recognition for various health conditions, create user profiles, search NHS services & store favourite articles.',
    info2: 'Tech Stack: Java, Spring, React, H2 Relational Database, Firebase.',
    url: 'https://nhs-voice-reg-app.web.app/',
    repo: 'https://github.com/tjp206/nhs-voice-search-app', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.png',
    title: 'MWA Auto Performance',
    info: 'Web app built & designed for a local business owner who wanted something, "simple, but effective."',
    info2: 'Tech Stack: React, HTML, CSS, Firebase.',
    url: 'https://mwa-autoperform.com/',
    repo: 'https://github.com/tjp206/mwa-auto', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: 'Want to find out more? Awesome!',
  btn: "Let's Talk",
  email: 'tjpataria@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    // {
    //   id: nanoid(),
    //   name: 'twitter',
    //   url: '',
    // },
    // {
    //   id: nanoid(),
    //   name: 'codepen',
    //   url: '',
    // },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://linkedin.com/in/tjp206',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/tjp206',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
